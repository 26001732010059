import React from "react";
import { Link } from "react-router-dom";

const linkList = [
  { itemName: "About Us", link: "/about-us/" },
  { itemName: "BNT Home", link: "https://bntwindowsanddoors.com/services/#residential" },
  { itemName: "More About State Grant", link: "https://mysafeflhome.com" },
];

const Product = [
  {
    itemName: "Works",
    link: "/",
  },
  {
    itemName: "About",
    link: "/",
  },
  {
    itemName: "News",
    link: "/",
  },
  {
    itemName: "Contact",
    link: "/",
  },
];

const Footer = () => {
  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div
            className="ptf-footer-heading has-black-color"
            style={{ maxWidth: "37.5rem" }}
          >
            For additional information, please click on the chatbot icon to your right
          </div>

          {/*<div className="ptf-footer-heading has-black-color">*/}
          {/*  Start by*/}
          {/*  <Link*/}
          {/*    className="has-accent-1 text-decoration-underline"*/}
          {/*    to="/contact"*/}
          {/*  >*/}
          {/*    Saying Hi!*/}
          {/*  </Link>*/}
          {/*</div>*/}

          {/*<p className="fz-24">590 SW 9th Terrace, Suite 1, 33069</p>*/}
          {/*<a className="fz-40 has-black-color" href="#">*/}
          {/*  BNT AI Chat BOT*/}
          {/*</a>*/}
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-3">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-black-color">
            <h4 className="ptf-widget-title">Links</h4>
            <ul>
              {linkList.map((val, i) => (
                <li key={i}>
                  <Link to={val.link} target={"_blank"}> {val.itemName}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
