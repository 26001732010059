import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../social/Social";

const menuContent = [
  {
    name: "Home",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "Home",
        routerPath: "/",
        target: "_self",
      },
      {
        name: "BNT Home",
        routerPath: "https://bntwindowsanddoors.com/",
        target: "_blank",
      },
    ],
  },
  {
    name: "About",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "About Us",
        routerPath: "/about-us"
        // routerPath: "https://bntwindowsanddoors.com/about-us/",
      },
      {
        name: "FAQ",
        routerPath: "/faq",
      },
    ],
  },
  {
    name: "Apply",
    activeClass: "",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "Apply Now",
        routerPath: "/apply",
      },
    ],
  },
  {
    name: "Resources",
    activeClass: "",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "Florida State Program",
        routerPath: "https://mysafeflhome.com/",
        target: "_blank",
      },
    ],
  },
];


const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              {menuContent.map((item, i) => (
                  <SubMenu title={item.name} key={i}>
                    {item.dropDownItems.map((val, i) => (
                        <MenuItem key={i}>
                          <Link to={val.routerPath} target={val.target}>{val.name}</Link>
                        </MenuItem>
                    ))}
                  </SubMenu>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}
      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          ©{new Date().getFullYear()}{" "}
          <span className="has-white-color">
              BNT Impact Windows & Doors
          </span>
          . All Rights Reserved.
        </p>
        <Social />
      </div>
      {/*<div className="ptf-offcanvas-menu__footer">*/}
      {/*  <p className="ptf-offcanvas-menu__copyright">*/}
      {/*    @{new Date().getFullYear()} <span>Moonex</span>. All Rights Reserved.{" "}*/}
      {/*    <br />*/}
      {/*    Development by{" "}*/}
      {/*    <span>*/}
      {/*      <a*/}
      {/*        href="https://themeforest.net/user/ib-themes"*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener noreferrer"*/}
      {/*      >*/}
      {/*        ib-themes*/}
      {/*      </a>*/}
      {/*    </span>*/}
      {/*    .*/}
      {/*  </p>*/}
      {/*  <Social />*/}
      {/*</div>*/}
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
