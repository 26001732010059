import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    activeClass: "sf-with-ul",
    menuClass: "one-column current-menu-item",
    dropDownItems: [
      {
        name: "Home",
        routerPath: "/",
        target: "_self",
      },
      {
        name: "BNT Home",
        routerPath: "https://bntwindowsanddoors.com/",
        target: "_blank",
      },
    ],
  },
  {
    name: "About",
    activeClass: "",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "About Us",
        routerPath: "/about-us"
        // routerPath: "https://bntwindowsanddoors.com/about-us/",
      },
      {
        name: "FAQ",
        routerPath: "/faq",
      },
    ],
  },

  {
    name: "Apply",
    activeClass: "",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "Apply Now",
        routerPath: "/apply",
      },
    ],
  },
  {
    name: "Resources",
    activeClass: "",
    menuClass: "one-column",
    dropDownItems: [
      {
        name: "Florida State Program",
        routerPath: "https://mysafeflhome.com/",
        target: "_blank",
      },
    ],
  },
];

const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          <a href="#" className={item.activeClass}>
            <span>{item.name}</span>
          </a>

          <ul className="sub-menu mega">
            {item.dropDownItems.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath} target={val.target}>
                  {" "}
                  <span>{val.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
export default DropdownMenu;
