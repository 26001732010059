import React from "react";
import { Link } from "react-router-dom";

const listContent = [
  "Impact Doors",
  "Custom Impact Doors",
  "Impact Windows",
  "Hurricane Impact Windows",
];

const ListOne = () => {
  return (
    <ul
      className="ptf-services-list ptf-services-list--style-5"
      style={{ "--ptf-font-family": "var(--ptf-primary-font)" }}
    >
      {listContent.map((val, i) => (
        <li key={i}>
          {/*<Link>{val}</Link>*/}
          {val}
        </li>
      ))}
    </ul>
  );
};

export default ListOne;
