import React from "react";
import { Link } from "react-router-dom";

const listContent = [
  "Keeps UV Outside & Keeps You Cool",
  "Burglar-Resistant Impact Windows",
  "Appreciation in Property Value",
  "Noise Reduction & Peace Of Mind",
  "Year-Round Protection",
  "Homeowner’s Insurance Discount",
];

const ListTwo = () => {
  return (
    <ul
      className="ptf-services-list ptf-services-list--style-5"
      style={{ "--ptf-font-family": "var(--ptf-primary-font)" }}
    >
      {listContent.map((val, i) => (
        <li key={i}>
          {val}
        </li>
      ))}
    </ul>
  );
};

export default ListTwo;
