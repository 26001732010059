import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
    //TODO
  { itemName: "FAQ", link: "/faq" },
  { itemName: "MSFH Program", link: "https://mysafeflhome.com" },
  { itemName: "About Us", link: "https://bntwindowsanddoors.com/about-us/" },
  { itemName: "More Details", link: "https://bntwindowsanddoors.com/services/#residential" },
  // { itemName: "Works", link: "" },
  // { itemName: "Studio", link: "" },
  // { itemName: "News", link: "" },
];

const FooterList = () => {
  return (
    <ul>
      {footerItems.map((item, i) => (
        <li key={i}>
          <Link to={item.link}>{item.itemName}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterList;
