import React, { useEffect,useState } from "react";
import { useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const ContactForm = () => {
    // for validation
    const eligiblePincodes =
        [
            // Dania Beach, FL
            "33004",
            // Hallandale Beach, FL
            "33009",
            // Hollywood, FL
            "33019", "33020", "33021",
            // Pompano Beach, FL
            "33060", "33062", "33064", "33068",
            // Fort Lauderdale, FL (33301 - 33394)
            "33301", "33302", "33303", "33304", "33305", "33306", "33307", "33308", "33309", "33310", "33311", "33312", "33313", "33314", "33315", "33316", "33317", "33318", "33319", "33320", "33321", "33322", "33323", "33324", "33325", "33326", "33327", "33328", "33329", "33330", "33331", "33332", "33334", "33335", "33336", "33337", "33338", "33339", "33340", "33341", "33342", "33343", "33344", "33345", "33346", "33347", "33348", "33349", "33350", "33351", "33352", "33353", "33354", "33355", "33356", "33357", "33358", "33359", "33360", "33361", "33362", "33363", "33364", "33365", "33366", "33367", "33368", "33369", "33370", "33371", "33372", "33373", "33374", "33375", "33376", "33377", "33378", "33379", "33380", "33381", "33382", "33383", "33384", "33385", "33386", "33387", "33388", "33389", "33390", "33391", "33392", "33393", "33394"
        ]

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || []; function
      gtag(){window.dataLayer.push(arguments);} gtag('js', new Date());
      gtag('config', 'AW-974350807');
    }
  });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phone: Yup.string().required("Phone Number is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Entered value does not match email format"),
        pincode: Yup.string().required("Pin code is required"),
        address: Yup.string().required("Address is required"),
        // eligible: Yup.string().required("Address is required"),
        acceptTerms: Yup.bool().oneOf(
            [true],
            "Accept Terms and Conditions is required"
        ),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, watch, setValue, formState } = useForm(formOptions);
    const { errors } = formState;
    // Watch for changes in pincode
    const pincode = watch("pincode");
    // State for error message
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');
    useEffect(() => {
        // Define eligible pin codes

        // Set eligibility based on pincode
        if (eligiblePincodes.includes(pincode)) {
            setValue("eligible", "yes")
        } else {
            setValue("eligible", "no")
        }

    }, [pincode, setValue]);

    function onSubmit(data, e) {

      // Prevent the default form submission behavior
      e.preventDefault();

      // API endpoint
      const apiEndpoint = "https://api.myfloridasafehomes.com/addRow";
      // Reset previous error message
      setSubmitError('');
      setSubmitSuccess('');
      // Call the API using fetch
      fetch(apiEndpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
          .then(response => response.json())
          .then(data1 => {
            console.log('Success:', data1);
            e.target.reset();
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('eligible', data.eligible);
            const newUrl = window.location.pathname + '?' + urlParams.toString();
            window.history.pushState({ path: newUrl }, '', newUrl);

            setSubmitSuccess('We have received your information. We will reach out to you soon.');
            if (window.dataLayer) {
              console.log("Pushing event to dataLayer");
              window.dataLayer.push(
                'event', 'conversion', {'send_to': 'AW-974350807/WUFKCIeyvIwZENfTzdAD'}
              );
            }
          })
          .catch((error) => {
              console.error('Error:', error);
              setSubmitError('Failed to submit the form. Please try again.');
          });
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="contact_form">
            <div className="ptf-form-group">
                <label data-number="01">What’s your first name?</label>
                <input
                    type="text"
                    name="firstName"
                    {...register("firstName")}
                    className={`${errors.firstName ? "is-invalid" : ""}`}
                />
                {errors.firstName && (
                    <div className="invalid-feedback">{errors.firstName?.message}</div>
                )}
            </div>
            {/* End .ptf-form-group */}

            <div className="ptf-form-group">
                <label data-number="02">What’s your last name?</label>
                <input
                    type="text"
                    name="lastName"
                    {...register("lastName")}
                    className={`${errors.lastName ? "is-invalid" : ""}`}
                />
                {errors.lastName && (
                    <div className="invalid-feedback">{errors.lastName?.message}</div>
                )}
            </div>
            {/* End .ptf-form-group */}

            <div className="ptf-form-group">
                <label data-number="03">What’s your email address?</label>
                <input
                    name="email"
                    type="text"
                    {...register("email")}
                    className={` ${errors.email ? "is-invalid" : ""}`}
                />
                {errors.email && (
                    <div className="invalid-feedback">{errors.email?.message}</div>
                )}
            </div>
            {/* End .ptf-form-group */}
            <div className="ptf-form-group">
                <label data-number="04">What’s your phone number ?</label>
                <input
                    name="phone"
                    type="number"
                    {...register("phone")}
                    className={` ${errors.phone ? "is-invalid" : ""}`}
                />
                {errors.phone && (
                    <div className="invalid-feedback">{errors.phone?.message}</div>
                )}
            </div>

            {/* End .ptf-form-group */}

            <div className="ptf-form-group">
                <label data-number="05">Whats your street address ?</label>
                <input
                    type="text"
                    name="address"
                    {...register("address")}
                    className={`${errors.address ? "is-invalid" : ""}`}
                />
                {errors.address && (
                    <div className="invalid-feedback">{errors.address?.message}</div>
                )}
            </div>
            {/* End .ptf-form-group */}

            <div className="ptf-form-group">
                <label data-number="06">Whats your zip code?</label>
                <input
                    type="number"
                    name="pincode"
                    {...register("pincode")}
                    className={`${errors.pincode ? "is-invalid" : ""}`}
                />
                {errors.pincode && (
                    <div className="invalid-feedback">{errors.pincode?.message}</div>
                )}
            </div>

            <div className="ptf-form-group" hidden={true}>
                <label data-number="07">Eligible for grant ? (We will double confirm this during evaluation)</label>
                <input
                    type="text"
                    name="eligible"
                    {...register("eligible")}
                    className={`${errors.eligible ? "is-invalid" : ""}`}
                />
                {errors.eligible && (
                    <div className="invalid-feedback">{errors.eligible?.message}</div>
                )}

            </div>

            {/* <!--Spacer--> */}
            <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>

            <div className="ptf-form-group agreement-checkbox ">
                <input
                    name="acceptTerms"
                    type="checkbox"
                    id="acceptTerms"
                    {...register("acceptTerms")}
                    className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
                />

                <label className="ptf-checkbox" htmlFor="acceptTerms">
                    <span className="ptf-checkbox__checkmark"></span>I agree to authorize BNT to perform an independent assessment on my grant eligibility and if eligible, represent as my contractor.
                </label>
                {errors.acceptTerms && (
                    <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
                )}
            </div>
            {/* End .ptf-form-group */}

            {/* <!--Spacer--> */}
            <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }}></div>
            {submitError !== '' && (
                <div className="submit-error-message">
                  {submitError}
                </div>
            )}
            {submitSuccess !== '' && (
              <div className="submit-success-message">
                {submitSuccess}
              </div>
            )}
            <button className="ptf-submit-button">
                Submit
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 17 17"
                >
                    <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
            </button>
            {/* End .ptf-submit-button */}
        </form>
    );
};

export default ContactForm;
