import React from "react";
import { Link } from "react-router-dom";

const faqContent = [
  {
    title: "Products",
    faqInner: [
      {
        title: "WHAT ARE IMPACT WINDOWS AND DOORS?",
        descriptions: `Impact products protect against extreme weather, would-be intruders, outside noises, and much more. Impact doors and windows are built with a durable interlayer that helps prevent destructive elements from entering your home. `,
      },
      {
        title: "ARE IMPACT WINDOWS WORTH THE COST?",
        descriptions: `
        Impact windows deliver superior protection from hurricanes and extreme weather — and priceless peace of mind. When a storm hits, you’ll know you’re already protected.  If your home’s openings are protected by impact-resistant windows and doors, you could qualify for a LOWER INSURANCE PREMIUM. Check with your insurance carrier for more information on available discounts. The return on investment with impact windows ranges from 80% – 86%.`,
      },
      {
        title: "HOW DO I CHECK UP ON BNT IMPACT WINDOWS AND DOORS?",
        descriptions: `
        Try the Better Business Bureau for starters. With more than 1300 installations per year, we have maintained an A+ Rating. Also, we have excellent marks & reviews on Angie’s List, Houzz, Yelp, and various social media sites. Ask the window and door specialists for links to their sites, respectively. `,
      },
      {
        title: "IS VINYL OR ALUMINUM THE BEST OPTION FOR ME?",
        descriptions: `
        Both materials may be able to fulfill your needs. The PROs and CONs will be explained by your consultant, who will make the best recommendation based on your needs, budget & taste.`,
      },
      {
        title: "DO YOU OFFER A NO-OBLIGATION ESTIMATE?",
        descriptions: `
        Yes, we have a qualified door and window specialist who will assess your project carefully and offer options that fit your style and budget.`,
      },
    ],
  },
  {
    title: "Installation",
    faqInner: [
      {
        title: "HOW LONG DO I HAVE TO WAIT FOR MY NEW WINDOWS/DOOR?",
        descriptions: `
        Somewhere between 6 to 10 weeks. It will depend on the time of year and the product purchased. The sales consultant can best answer this while in the home for the estimate.`,
      },
      {
        title: "WILL BNT IMPACT WINDOWS AND DOORS STAIN OR PAINT MY (ANY AND/OR ENTRY) DOORS AFTER INSTALLATION?",
        descriptions: `
        No, BNT instead has a professional painter we recommend for stain or paint of our doors. Please ask your rep for details. Links, photos, and samples are available upon request. `,
      },
      {
        title: "DOES BNT IMPACT WINDOW AND DOORS PROTECT MY FLOORS & BELONGINGS?",
        descriptions: `
        Our factory-authorized installers will use drop cloths, zip walls & runners to protect the floors. Customers may want to cover furniture with bed sheets for dust protection.`,
      },
      {
        title: "DOES THE CUSTOMER NEED TO PROVIDE A DUMPSTER?",
        descriptions: `
        No, BNT Impact Windows and Doors will remove the old product from the property and all job-related debris. We even shop vacuum & sweep.  Please keep in mind you might have to hire a professional cleaning crew.`,
      },
      {
        title: "DO I NEED TO PULL PERMITS",
        descriptions: `
        BNT Impact Windows and Doors will pull permits and BNT Impact Windows and Doors is licensed, bonded & insured.`,
      },
      {
        title: "WILL MY WINDOW TREATMENTS WORK AS IS?",
        descriptions: `
        The BNT Impact Windows and Doors sales consultant will be able to answer that best when they are in the home. Each application is different.`,
      },
      {
        title: "WHAT WILL HAPPEN TO MY SHUTTERS OUTSIDE OF MY HOUSE?",
        descriptions: `
        The BNT Impact Windows and Doors sales consultant will be able to answer that best when they are in the home. Each application is different.`,
      },
      {
        title: "WILL MY HOME BE A MESS DURING INSTALLATION?",
        descriptions: `
        Not really! Our installers will use your driveway or garage for all saw work. We will even sweep the driveway upon completion. Dust is kept to a minimum on the inside of your home.`,
      },
      {
        title: "DO I NEED TO TAKE OFF WORK FOR INSTALLATION?",
        descriptions: `
        An entire house of windows can take 2-4 days on average or more for over 15 windows. BNT Impact Windows and Doors attempts to make the installation process easy.`,
      },
      {
        title: "DOES BNT WINDOWS & DOOR DELIVER THE WINDOWS TO ME BEFORE INSTALLATION?",
        descriptions: `
        BNT stores all products in our warehouse until the day of installation.`,
      },
    ],
  },
  {
    title: "Payment",
    faqInner: [
      {
        title: "DO YOU OFFER PAYMENT OPTIONS?",
        descriptions: `We offer checks, cash, and most major credit cards for your convenience. Financing is also an option through BNT Impact Window and Doors. Financing is available through the PACE program and Synchrony Financing. `,
      },
      {
        title: "DO YOU HAVE LIABILITY & WORKER COMP INSURANCE?",
        descriptions: `
        Yes, we carry full General Liability and Worker Compensation Insurance.`,
      },
    ],
  },
  {
    title: "Support",
    faqInner: [
      {
        title: "WHO DO I CALL IF THERE IS A SERVICE ISSUE?",
        descriptions: `BNT Impact Windows and Doors has a full installation and service team with 15 trucks in the field. Just call or click and talk to a door and window professional immediately. If a house call is needed, we will schedule a convenient time with one of our service professionals.  `,
      },
      {
        title: "HOW SHOULD I CLEAN MY DOOR AND/OR WINDOWS WHEN THEY GET DIRTY?",
        descriptions: `Consult your owner’s manual for specific manufacturer instructions. Mild soap and water usually work well for exteriors.`,
      },
      {
        title: "IS THERE A MANUFACTURER’S WARRANTY?",
        descriptions: `Yes, each manufacturer offers its own product warranties. `,
      },
      {
        title: "WHAT IS THE LABOR WARRANTY?",
        descriptions: `The Labor warranty is two years from the date of installation.`,
      },
    ],
  },
  {
    title: "MSFH Program",
    faqInner: [
      {
        title: "WHERE CAN I FIND DETAILS ABOUT MSFH PROGRAM?",
        descriptions: `Ask our AI Chat Bot for more details about My Safe Florida Home Grant Program.`,
      },
    ],
  },
];

const FaqTwo = () => {
  return (
      <section>
        {/* <!--Spacer--> */}

        <div className="container-xxl">
          {faqContent.map((item, i) => (
              <div key={i}>
                <div className="ptf-animated-block" data-aos="fade">
                  {/* <!--FAQ--> */}
                  <div className="ptf-faq">
                    <div className="ptf-faq__category">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="ptf-faq__content">
                      {item.faqInner.map((val, i) => (
                          <div className="ptf-faq__item" key={i}>
                            <div className="ptf-faq__question">
                              <h6>{val.title}</h6>
                            </div>
                            <div className="ptf-faq__answer">
                              <p>{val.descriptions}</p>
                            </div>
                          </div>
                      ))}
                      {/* End .ptf-faq__item */}
                    </div>
                  </div>
                </div>
                <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                ></div>
              </div>
          ))}

          {/* <!--Spacer--> */}
          {/*<div className="ptf-spacer" style={{ "--ptf-xxl": "4.375rem" }}></div>*/}
          {/* <!--Animated Block--> */}
          {/*<div className="ptf-animated-block" data-aos="fade">*/}
          {/*  <p className="fz-24">*/}
          {/*    Don’t find your question?{" "}*/}
          {/*    <Link*/}
          {/*        className="has-accent-4 text-decoration-underline"*/}
          {/*        to="/contact"*/}
          {/*    >*/}
          {/*      Contact us{" "}*/}
          {/*    </Link>*/}
          {/*    now, we alway willing to help you.*/}
          {/*  </p>*/}
          {/*</div>*/}
        </div>
        {/* <!--Spacer--> */}
        {/*<div*/}
        {/*    className="ptf-spacer"*/}
        {/*    style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}*/}
        {/*></div>*/}
      </section>
  );
};

export default FaqTwo;
