import React from "react";

const socialList = [
  {
    iconName: "socicon-instagram",
    link: "https://www.instagram.com/bntservices/",
    iconClass: "instagram",
  },
  {
    iconName: "socicon-facebook",
    link: "https://www.facebook.com/BNTIMPACTWINDOWS/",
  },
  {
    iconName: "socicon-twitter",
    link: "https://twitter.com/bntservices",
  },
  {
    iconName: "socicon-youtube",
    link: "https://www.youtube.com/channel/UCf0kILbd0H0zdGAAg1Hjnng",
  },
  {
    iconName: "socicon-linkedin",
    link: "https://www.linkedin.com/company/bnt-services-inc/",
  },
  {
    iconName: "socicon-google",
    link: "https://g.page/StormWindows/",
  },
  {
    iconName: "socicon-yelp",
    link: "https://www.yelp.com/biz/bnt-impact-windows-and-doors-pompano-beach",
  },
  {
    iconName: "socicon-houzz",
    link: "https://www.houzz.com/pro/bntserve/bnt-impact-windows-and-doors",
  },
];

const SocialTwo = () => {
  return (
    <>
      {socialList.map((val, i) => (
        <a
          className="ptf-social-icon ptf-social-icon--style-1"
          target="_blank"
          rel="noopener noreferrer"
          href={val.link}
          key={i}
        >
          <i className={val.iconName}></i>
        </a>
      ))}
    </>
  );
};

export default SocialTwo;
