import React from "react";
import FooterList from "../list/FooterList";

const FooterTwo = () => {


  const handleAgentCall = () => {

    // Send conversion event to Google Tag Manager
    if (window.dataLayer) {
      // Push an event to the dataLayer
      console.log("Pushing event to dataLayer");
      window.dataLayer.push({
        event: 'reach_agent', // Event name, used in GTM trigger
        category: 'User Interaction', // Optional: Event category
        action: 'Click', // Optional: Event action
        label: 'AI Chatbot' // Optional: Event label
      });
    }
    // Open Chatbot with Reach agent intent
    if (window.voiceflow && window.voiceflow.chat && window.voiceflow.chat.proactive) {
      window.voiceflow.chat.proactive.clear();
      window.voiceflow.chat.open();
      window.voiceflow.chat.interact({
        type: "intent",
        payload: {
          intent: {
            name: "reachAgent"
          },
          stage: "reach_agent",
          entities: []
        }
      });
    }
  }
  return (
    <div className="row">
      <div className="col-12 col-lg-7">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div className="ptf-widget ptf-widget-contacts has-white-color">
            <ul>
              <li>
                <span>Address</span>
                <p>
                  590 SW 9th Terrace, Suite 1, 33069
                </p>
              </li>
              <li>
                <span>Phone</span>
                <button variant="contained" onClick={handleAgentCall} className="ptf-btn ptf-btn-slim-primary">
                  Request a call
                </button>
              </li>
              {/*<li>*/}
              {/*  <span>Email</span>*/}
              {/*  /!*<a href="mailto:info@bntimpact.com">*!/*/}
              {/*  /!*  info@bntimpact.com*!/*/}
              {/*  /!*</a>*!/*/}
              {/*</li>*/}
            </ul>
          </div>
        </div>
      </div>
      {/* End .col */}

      <div className="col-12 col-lg-5">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-white-color">
            <FooterList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTwo;
