import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Footer from "../../components/footer/Footer";
import Social from "../../components/social/Social";
import ContactForm from "../../components/ContactForm";
import Address from "../../components/Address";
import * as Yup from "yup";

const Contact = () => {
  const [currentSection, setCurrentSection] = useState(1);

  const [iframeWidth, setIframeWidth] = useState(1022); // Initial width
  const [iframeHeight, setIframeHeight] = useState(575);

  useEffect(() => {
    // Function to update iframe size based on window dimensions
    function resizeIframe() {
      const newWidth = window.innerWidth * 0.85; // Adjust as needed
      const newHeight = (9 / 16) * newWidth; // 16:9 aspect ratio, adjust as needed

      setIframeWidth(newWidth);
      setIframeHeight(newHeight);
    }

    // Call the resizeIframe function when the page loads
    resizeIframe();

    // Attach event listener for window resize
    window.addEventListener('resize', resizeIframe);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', resizeIframe);
    };

  }, []);

  const watchVideo = ()=> {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('watchvideo', 'yes');
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
    setCurrentSection(2);
  }

  return (
    <div>
      <Helmet>
        <title>Apply Grant</title>
        <script async
                                              src="https://www.googletagmanager.com/gtag/js?id=AW-974350807"></script>
        <script>{` window.dataLayer = window.dataLayer || []; function
          gtag(){dataLayer.push(arguments);} gtag('js', new Date());
          gtag('config', 'AW-974350807'); `}</script>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          {currentSection === 1 && (<>
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "10rem", "--ptf-md": "5rem"}}
              ></div>

              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-12">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">
                        First Step: <br/>
                        Please watch the video below
                      </h1>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "3rem", "--ptf-md": "2.5rem"}}
                      ></div>

                      <Social/>
                      {/* <!--Social Icon--> */}
                    </div>
                  </div>
                </div>
                {/* End .row */}
              </div>
              {/* Contact top portion */}

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem"}}
              ></div>
            </section>
            <section>
              <div className="container-xxl">
                <div className="row">
                  {/*<div className="col-lg-4">*/}
                  {/*  <Address />*/}
                  {/*</div>*/}
                  {/* End .col */}

                  <div className="col-lg-12">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="300"
                    >
                      <h2 className="main-title fw-bold tx-dark">Watch a quick video to find out more.</h2>
                      <br/>
                      {/*<h2 className="main-title fw-bold tx-dark">Watch a quick video<img src="/images/shape/shape_96.svg" alt="shape"/> to find out more.</h2>*/}
                      <div className={`mt-60 lg-mt-30`}>
                        <div className="ptf-page-video-container text-center mt-4">
                          <iframe width={iframeWidth} height={iframeHeight}
                                  className="ptf-page-responsive-iframe"
                                  src="https://www.youtube.com/embed/05QcPBI0zP4?rel=0"
                                  title="My Florida Home - State Grants - Cities Grants - To Improve Your Home Protection Against Hurricanes"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen>
                          </iframe>
                        </div>
                        <button className="ptf-btn ptf-btn-long-secondary" onClick={watchVideo}
                                style={{display: 'flex', marginTop: '5px'}}>
                          I acknowledge that I have watched <br/>
                          the video and want to proceed.
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End .col */}
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "10rem", "--ptf-md": "5rem"}}
              ></div>
            </section>
          </>)}
          {currentSection === 2 && (<>
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "10rem", "--ptf-md": "5rem"}}
              ></div>

              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-10">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">
                        Final Step: <br/>
                        Request Inspection & Grant Eligibility
                      </h1>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "3rem", "--ptf-md": "2.5rem"}}
                      ></div>

                      <Social/>
                      {/* <!--Social Icon--> */}
                    </div>
                  </div>
                </div>
                {/* End .row */}
              </div>
              {/* Contact top portion */}

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End section contact header */}

          <section>
            <div className="container-xxl">
              <div className="row">
                {/*<div className="col-lg-4">*/}
                {/*  <Address />*/}
                {/*</div>*/}
                {/* End .col */}

                <div className="col-lg-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <h5 className="fz-14 text-uppercase has-3-color fw-normal">
                      Tell us about your home
                    </h5>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    ></div>
                    <ContactForm />
                    {/* End ContactForm */}
                  </div>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
          </section>
          </>)}
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
