import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ModalVideo from "react-modal-video";
import Contact from "../../views/inner-pages/Contact";

const HeroAgency = () => {
  const [isOpen, setOpen] = useState(false);



  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || []; function
      gtag(){window.dataLayer.push(arguments);} gtag('js', new Date());
      gtag('config', 'AW-974350807');
    }
  });

  const handleAgentCall = () => {
    // one message
    if (window.voiceflow && window.voiceflow.chat && window.voiceflow.chat.proactive) {

      if (window.dataLayer) {
        console.log("Pushing event to dataLayer");
        window.dataLayer.push(
          'event', 'conversion', {'send_to': 'AW-974350807/WUFKCIeyvIwZENfTzdAD'}
        );
      }
      window.voiceflow.chat.proactive.clear();
      window.voiceflow.chat.open();
      // window.voiceflow.chat.interact({
      //   type: "intent",
      //   payload: {
      //     intent: {
      //       name: "reachAgent"
      //     },
      //     stage: "reach_agent",
      //     entities: []
      //   }
      // });
    }
  }

  const handleApplication = () => {
    return(<Contact/>
    );
  };


  // const handleApplyMFSH = () => {
  //   // one message
  //   if (window.voiceflow && window.voiceflow.chat && window.voiceflow.chat.proactive) {
  //
  //     console.log(window.voiceflow.chat);
  //     window.voiceflow.chat.open();
  //     window.voiceflow.chat.proactive.clear();
  //     window.voiceflow.chat.interact({
  //       type: "intent",
  //       payload: {
  //         intent: {
  //           name: "applyMFSH"
  //         },
  //         stage: "apply_MFSH",
  //         entities: []
  //       }
  //     });
  //   }
  // }

  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    fade: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // adaptiveHeight: true,
  };
  return (
    <>
      <Slider {...settings}>
        <div className="ptf-showcase-image image-1">
          <div className="ptf-showcase-slide">
            <div className="container">
              <h1 className="fz-100 fz-90--lg lh-1 has-white-color text-center">
                <span className="has-accent-1">Safer Homes</span> with <br />
                $500 Million in State Funds
              </h1>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.125rem" }}
              ></div>

              <p
                className="fz-24 has-white-color text-center"
                style={{ maxWidth: "34.375rem", margin: "0 auto" }}
              >
                Access to FREE hurricane home fortification through BNT, a State-Authorized Contractor, via a MSFH program with Expert Guidance
              </p>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.625rem" }}
              ></div>

              <div className="d-flex justify-content-center">
                {/* <!--Button--> */}
                {/*<ChatWidgetButton/>*/}
                {/*<a className="ptf-btn ptf-btn--primary" href="" target="_self">*/}
                {/*  BNT AI BOT*/}
                {/*</a>*/}
                {/* <!--Button--> */}
                <button variant="contained" onClick={handleAgentCall} className="ptf-btn ptf-btn--primary">
                  Ask AI Bot
                </button>
                {/* <!--Button--> */}
                {/*<Link*/}
                {/*  className="ptf-btn ptf-btn--secondary"*/}
                {/*  to="#"*/}
                {/*  style={{marginLeft: "2rem"}}*/}
                {/*>*/}
                {/*  Get Started*/}
                {/*</Link>*/}

                {/*<button variant="contained" onClick={handleApplyMFSH} className="ptf-btn ptf-btn--secondary" style={{marginLeft: "2rem"}}>*/}
                {/*  Get Started*/}
                {/*</button>*/}
                <a className="ptf-btn ptf-btn--secondary" style={{marginLeft: "2rem"}} href="/apply" target="_self">
                  Apply Now
                </a>
                {/*<button variant="contained" onClick={handleApplication} className="ptf-btn ptf-btn--secondary" style={{marginLeft: "2rem"}}>*/}
                {/*  Apply Now*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
        </div>
        {/* End .ptf-showcase-image */}

        <div className="ptf-showcase-image image-2">
          <div className="ptf-showcase-slide">
            <div className="container">
              <h1 className="fz-100 fz-90--lg lh-1 has-white-color text-center">
                <span className="has-accent-1">Choose BNT </span> for <br />
                Easy Path to Peace of Mind 
              </h1>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.125rem" }}
              ></div>
              <p
                className="fz-24 has-white-color text-center"
                style={{ maxWidth: "34.375rem", margin: "0 auto" }}
              >
                Access Funds through BNT for FREE as part of Hurricane Home Fortification Program in South Florida. Check your Eligibility NOW
              </p>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.625rem" }}
              ></div>
              <div className="d-flex justify-content-center">
                {/* <!--Button--> */}
                <button variant="contained" onClick={handleAgentCall} className="ptf-btn ptf-btn--primary">
                  Ask AI Bot
                </button>
                {/* <!--Button--> */}
              </div>
            </div>
          </div>
        </div>
        {/* End .ptf-showcase-image */}

        <div className="ptf-showcase-image image-3">
        <div className="ptf-showcase-slide">
            <div className="container">
              <h1 className="fz-100 fz-90--lg lh-1 has-white-color">
                <span className="has-accent-1">Act Wisely </span> <br />
                Before the Hurricane Strikes
              </h1>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem" }}
              ></div>
              <p
                className="fz-24 has-white-color"
                style={{ maxWidth: "34.375rem" }}
              >
                Start with a FREE Hurricane Home Inspection from BNT experts today. Ask our AI Bot for Seamless Processing of the Grant Quickly
              </p>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.625rem" }}
              ></div>
              <div className="d-flex">
                {/* <!--Button--> */}
                <button variant="contained" onClick={handleAgentCall} className="ptf-btn ptf-btn--primary">
                  Ask AI Bot
                </button>
                {/* <!--Video Button--> */}
                <div
                  className="ptf-video-button"
                  onClick={() => setOpen(true)}
                  style={{
                    "--ptf-title-color": "var(--ptf-color-white)",
                    marginLeft: "2rem",
                  }}
                >
                  <a href="#" rel="nofollow">
                    <i className="lnil lnil-play"></i>
                  </a>
                  <div className="ptf-video-button__title">
                    Safe Home Florida Grant
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
        {/* End .ptf-showcase-image */}
      </Slider>
      <ModalVideo
        channel="youtube"
        autoplay={false}
        isOpen={isOpen}
        videoId="cNdRLmiM3Ro"
        onClose={() => setOpen(false)}
      />
      {/* End Youtube Modal video */}
    </>
  );
};

export default HeroAgency;
