import React from "react";
import { Link } from "react-router-dom";

const serviceContent = [
  {
    icon: "lnil lnil-cup",
    titlePart1: "ELITE",
    titlePart2: "CUSTOMER SERVICE",
    descriptions: ` First-class customer service, industry knowledge, and craftsmanship.`,
  },
  {
    icon: "lnil lnil-website-rank",
    titlePart1: "Solutions-Based",
    titlePart2: "Decisions",
    descriptions: ` High-quality solutions-focused process with access to the leading edge of industry products and technology.`,
  },
  {
    icon: "lnil lnil-3d",
    titlePart1: "Educational",
    titlePart2: "Sales Approach",
    descriptions: ` Educational window and door buying experience that empowers our customers.`,
  },
  {
    icon: "lnil lnil-video-camera-alt-1",
    titlePart1: "Hard Work",
    titlePart2: "& Dedication",
    descriptions: ` Set a new standard of excellence for window and door commercial contractors.`,
  },
];

const ServiceOne = () => {
  return (
    <>
      {serviceContent.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          {/* <!--Services Box--> */}
          <div className="ptf-service-box">
            {/*<Link*/}
            {/*  to="/service-details"*/}
            {/*  className="ptf-service-box__link"*/}
            {/*></Link>*/}
            <div className="ptf-service-box__icon">
              <i className={val.icon}></i>
            </div>
            <h5 className="ptf-service-box__title">
              {val.titlePart1} <br />
              {val.titlePart2}
            </h5>
            <div className="ptf-service-box__content">
              <p>{val.descriptions}</p>
            </div>
            {/*<div className="ptf-service-box__arrow">*/}
            {/*  <i className="lnil lnil-chevron-right"></i>*/}
            {/*</div>*/}
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceOne;
