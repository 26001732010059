import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CounterTwo from "../../components/counter/CounterTwo";
import CopyRightTwo from "../../components/footer/copyright/CopyRightTwo";
import FooterTwo from "../../components/footer/FooterTwo";
import HeaderAgency from "../../components/header/HeaderAgency";
import HeroAgency from "../../components/hero/HeroAgency";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import ListOne from "../../components/list/ListOne";
import ListTwo from "../../components/list/ListTwo";
import PortfolioTwo from "../../components/portfolio/PortfolioTwo";
// import Pricing from "../../components/pricing/Pricing";
import Team from "../../components/team/Team";
// import TestimonialTwo from "../../components/testimonial/TestimonialTwo";

const HomeAgency = () => {
  // window.addEventListener('message', (event) => {
  //   if (event && event.data) {
  //     var eventtype = event.data ;
  //     if (typeof eventtype === 'string' && eventtype.startsWith('{"type":"voiceflow:')) {
  //       if (eventtype.includes("This won't take long.")) {
  //         console.log(eventtype);
  //         window.voiceflow.chat.close();
  //       }
  //     }
  //   }
  //
  // }, false);

  return (

    <div className="ptf-site-wrapper animsition ptf-is--home-agency">
      <Helmet>
        <title>Storm Ready with BNT</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="ptf-site-wrapper__inner">
        <HeaderAgency/>
        {/* End Header Agency */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-agency">
            {/*=============================================
          Start Our Hero Section howcase 3
          ============================================== */}
            <div className="ptf-showcase-3 swiper-container">
              <div className="swiper-wrapper">
                <HeroAgency/>
              </div>
            </div>

            {/*=============================================
          Start Call to Counter section
          ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "3.75rem"}}
              ></div>
              <div className="container">
                <div
                  className="row align-items-center"
                  style={{"--bs-gutter-y": "2rem"}}
                >
                  <CounterTwo/>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "3.75rem"}}
              ></div>
            </section>

            <section>
              <div className="container">
                {/* <!--Divider--> */}
                <div className="ptf-divider"></div>
              </div>
            </section>
            {/* End .ptf-devider */}

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem"}}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading has-accent-1">
                        Start with a home inspection
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-5 d-none d-xl-block">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <div className="has-black-color fz-90 lh-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          style={{height: "1em"}}
                          viewBox="0 0 17 17"
                        >
                          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem"}}
              ></div>
            </section>
            {/* End  Advertising Big Title */}

            <section className="ptf-custom--3993 jarallax">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 offset-lg-7">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h6 className="fz-16 has-accent-1 text-uppercase">
                        No Cost, No Obligations
                      </h6>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "1.875rem"}}
                      ></div>
                      <h3 style={{maxWidth: "30rem"}}>
                        Get a free hurricane home inspection from BNT
                      </h3>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "5rem", "--ptf-md": "2.5rem"}}
                      ></div>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        Our experts will thoroughly inspect your single-family home or townhouse, free of charge and
                        with no obligation.
                      </p>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        You'll receive a detailed report on your home's hurricane resilience and tailored
                        recommendations for enhancements to bolster its strength & your eligibility for MSFH program.
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                  </div>
                </div>
              </div>
            </section>
            {/* End  Advertising Banner */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem"}}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading has-accent-1">
                        Why choose BNT?
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-5 d-none d-xl-block">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <div className="has-black-color fz-90 lh-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          style={{height: "1em"}}
                          viewBox="0 0 17 17"
                        >
                          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem"}}
              ></div>
            </section>
            {/* End  Advertising Big Title */}

            <section className="ptf-custom--3994 jarallax">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 offset-lg-7">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h6 className="fz-16 has-accent-1 text-uppercase">
                        Your Trusted Contractor
                      </h6>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "1.875rem"}}
                      ></div>
                      <h3 style={{maxWidth: "30rem"}}>
                        25+ Years of Protecting South Florida Homes and Businesses
                      </h3>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "5rem", "--ptf-md": "2.5rem"}}
                      ></div>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        BNT is committed to providing premier replacement impact window and door services to clients
                        throughout the South Florida area.
                      </p>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        Our dedication to quality craftsmanship is matched by our unwavering pledge to customer service.
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                  </div>
                </div>
              </div>
            </section>
            {/* End  Advertising Banner */}

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem"}}
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading has-accent-1">
                        Can I do it myself?
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-5 d-none d-xl-block">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <div className="has-black-color fz-90 lh-1 text-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          style={{height: "1em"}}
                          viewBox="0 0 17 17"
                        >
                          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem"}}
              ></div>
            </section>
            {/* End  Advertising Big Title */}

            <section className="ptf-custom--3995 jarallax">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 offset-lg-7">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h6 className="fz-16 has-accent-1 text-uppercase">
                        Ensure Compliance
                      </h6>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "1.875rem"}}
                      ></div>
                      <h3 style={{maxWidth: "30rem"}}>
                        Align to wind codes and avoid amateur errors
                      </h3>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "5rem", "--ptf-md": "2.5rem"}}
                      ></div>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        Improper window installation can result in significant costs, including the need to reorder
                        windows, ongoing issues like poor fit, higher energy bills, and interior damage from severe
                        weather.
                      </p>
                      <p className="fz-18" style={{maxWidth: "30rem"}}>
                        Once these winds breach a home, the roof and structure can quickly fail due to pressure buildup,
                        causing devastation within minutes. Installation of high-impact windows and doors by experts is
                        crucial.
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{"--ptf-xxl": "8.125rem", "--ptf-lg": "0"}}
                    ></div>
                  </div>
                </div>
              </div>
            </section>
            {/* End  Advertising Banner */}
            {/*=============================================
          Start Service section
          ============================================== */}

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "10rem", "--ptf-md": "5rem"}}
              ></div>
              <div className="container">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h1 large-heading has-accent-1">Our Products</h2>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{"--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem"}}
                ></div>
                <div
                  className="row"
                  style={{
                    "--bs-gutter-x": "10.625rem",
                    "--bs-gutter-y": " 5.3125rem",
                  }}
                >
                  <div className="col-12 col-md-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2>Windows and Doors</h2>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "1.5625rem"}}
                      ></div>
                      {/* <!--Divider--> */}
                      <div className="ptf-divider"></div>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "3.125rem"}}
                      ></div>
                      {/* <!--Services List--> */}
                      <ListOne/>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <h2>Advantages</h2>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "1.5625rem"}}
                      ></div>
                      {/* <!--Divider--> */}
                      <div className="ptf-divider"></div>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{"--ptf-xxl": "3.125rem"}}
                      ></div>
                      {/* <!--Services List--> */}
                      <ListTwo/>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--Spacer--> */}
              {/*<div*/}
              {/*  className="ptf-spacer"*/}
              {/*  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}*/}
              {/*></div>*/}
            </section>

            {/*=============================================
          Start Latest Works section
          ============================================== */}
            {/*  <section>*/}
            {/*  <div className="container">*/}
            {/*    /!* <!--Animated Block--> *!/*/}
            {/*    <div*/}
            {/*        className="ptf-animated-block"*/}
            {/*        data-aos="fade"*/}
            {/*        data-aos-delay="0"*/}
            {/*    >*/}
            {/*      <h2 className="h1 large-heading has-accent-1 d-inline-flex">*/}
            {/*        Latest Works*/}
            {/*      </h2>*/}
            {/*      <Link*/}
            {/*          className="ptf-link-with-arrow fz-18 text-uppercase d-none d-lg-inline-flex"*/}
            {/*          to="/works-masonry"*/}
            {/*          style={{ marginLeft: "5.625rem" }}*/}
            {/*      >*/}
            {/*        All Projects{" "}*/}
            {/*        <svg*/}
            {/*            xmlns="http://www.w3.org/2000/svg"*/}
            {/*            fill="currentColor"*/}
            {/*            style={{ height: "1em" }}*/}
            {/*            viewBox="0 0 17 17"*/}
            {/*        >*/}
            {/*          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />*/}
            {/*        </svg>*/}
            {/*      </Link>*/}
            {/*    </div>*/}
            {/*    /!* End .ptf-animated-block *!/*/}

            {/*    /!* <!--Spacer--> *!/*/}
            {/*    <div*/}
            {/*        className="ptf-spacer"*/}
            {/*        style={{ "--ptf-xxl": "6.25rem" }}*/}
            {/*    ></div>*/}

            {/*    /!* <!--Animated Block--> *!/*/}
            {/*    <div*/}
            {/*        className="ptf-animated-block"*/}
            {/*        data-aos="fade"*/}
            {/*        data-aos-delay="0"*/}
            {/*    >*/}
            {/*      <div className="ptf-isotope-grid">*/}
            {/*        <PortfolioTwo />*/}
            {/*      </div>*/}
            {/*      /!* End .ptf-isotope-grid *!/*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  /!* <!--Spacer--> *!/*/}
            {/*  <div*/}
            {/*      className="ptf-spacer"*/}
            {/*      style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}*/}
            {/*  ></div>*/}
            {/*</section>*/}

            {/*=============================================
          Start Our Crew/Team section
          ============================================== */}
            {/*<section className="ptf-custom--1569">*/}
            {/*  /!* <!--Spacer--> *!/*/}
            {/*  <div*/}
            {/*    className="ptf-spacer"*/}
            {/*    style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}*/}
            {/*  ></div>*/}
            {/*  <div className="container">*/}
            {/*    /!* <!--Animated Block--> *!/*/}
            {/*    <div*/}
            {/*      className="ptf-animated-block"*/}
            {/*      data-aos="fade"*/}
            {/*      data-aos-delay="0"*/}
            {/*    >*/}
            {/*      <h2 className="h1 large-heading has-accent-1">Our Crew</h2>*/}
            {/*    </div>*/}
            {/*    /!* <!--Spacer--> *!/*/}
            {/*    <div*/}
            {/*      className="ptf-spacer"*/}
            {/*      style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}*/}
            {/*    ></div>*/}
            {/*  </div>*/}
            {/*  <div className="container">*/}
            {/*    <div className="ptf-team-member-grid ptf-team-member-grid--4-columns">*/}
            {/*      <Team />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  /!* <!--Spacer--> *!/*/}
            {/*  <div*/}
            {/*    className="ptf-spacer"*/}
            {/*    style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}*/}
            {/*  ></div>*/}
            {/*</section>*/}

            {/*=============================================
            Start Pricing section
            ============================================== */}
            {/*<section>*/}
            {/*  /!* <!--Spacer--> *!/*/}
            {/*  <div*/}
            {/*    className="ptf-spacer"*/}
            {/*    style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}*/}
            {/*  ></div>*/}
            {/*  <div className="container">*/}
            {/*    /!* <!--Animated Block--> *!/*/}
            {/*    <div*/}
            {/*      className="ptf-animated-block"*/}
            {/*      data-aos="fade"*/}
            {/*      data-aos-delay="0"*/}
            {/*    >*/}
            {/*      <h2 className="h1 large-heading has-accent-1">*/}
            {/*        Pricing & Plan*/}
            {/*      </h2>*/}
            {/*    </div>*/}
            {/*    /!* <!--Spacer--> *!/*/}
            {/*    <div*/}
            {/*      className="ptf-spacer"*/}
            {/*      style={{ "--ptf-xxl": "3.75rem", "--ptf-md": "1.875rem" }}*/}
            {/*    ></div>*/}
            {/*  </div>*/}
            {/*  <div className="container">*/}
            {/*    <div className="row" style={{ "--bs-gutter-x": "2rem" }}>*/}
            {/*      <Pricing />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  /!* <!--Spacer--> *!/*/}
            {/*  <div*/}
            {/*    className="ptf-spacer"*/}
            {/*    style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}*/}
            {/*  ></div>*/}
            {/*</section>*/}

            {/*<section>*/}
            {/*  <div className="container">*/}
            {/*    /!* <!--Divider--> *!/*/}
            {/*    <div className="ptf-divider"></div>*/}
            {/*  </div>*/}
            {/*</section>*/}

            {/*=============================================
          Start Testimonial section
          ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{"--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem"}}
              ></div>
              {/*  <div className="container">*/}
              {/*    <div className="row align-items-center">*/}
              {/*      <div className="col-8">*/}
              {/*        /!* <!--Animated Block--> *!/*/}
              {/*        <div*/}
              {/*          className="ptf-animated-block"*/}
              {/*          data-aos="fade"*/}
              {/*          data-aos-delay="0"*/}
              {/*        >*/}
              {/*          <h2 className="h1 large-heading has-accent-1">*/}
              {/*            Trusted By Clients*/}
              {/*          </h2>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* <!--Spacer--> *!/*/}
              {/*    <div*/}
              {/*      className="ptf-spacer"*/}
              {/*      style={{ "--ptf-xxl": " 4.375rem" }}*/}
              {/*    ></div>*/}
              {/*    /!* <!--Animated Block--> *!/*/}
              {/*    <div*/}
              {/*      className="ptf-animated-block"*/}
              {/*      data-aos="fade"*/}
              {/*      data-aos-delay="0"*/}
              {/*    >*/}
              {/*      /!* <!--Content Slider--> *!/*/}
              {/*      <div className="ptf-content-slider swiper-container ">*/}
              {/*        <div className="swiper-wrapper testimonial-100-spacer">*/}
              {/*          <TestimonialTwo />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  /!* <!--Spacer--> *!/*/}
              {/*  <div*/}
              {/*    className="ptf-spacer"*/}
              {/*    style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}*/}
              {/*  ></div>*/}
            </section>

            {/*=============================================
          Start Footer section
          ============================================== */}
            <footer className="ptf-footer ptf-footer--style-3">
              <div className="container">
                <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                    <div className="ptf-footer__top">
                      <FooterTwo/>
                    </div>
                    {/* End .ptf-footer__top */}

                    <div className="ptf-footer__bottom">
                      <CopyRightTwo/>
                    </div>
                    {/* End .ptf-footer__bottom */}
                  </div>
                </div>
              </div>
            </footer>
          </div>
          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div>
      {/* End .ptf-site-wrapper__inner */}
    </div>
  );
};

export default HomeAgency;
