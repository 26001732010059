import React, { useEffect } from "react";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "photoswipe/dist/photoswipe.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Storm Ready with BNT</title>
        <meta name="description" content="Access Funds through BNT for FREE as part of Hurricane Home Fortification Program in South Florida"/>
        <meta
          name="keywords"
          content="MSFH, Miami, Miami-Dade, Broward, Fort Lauderdale, Hurricane, Home, Fortification, Program, Storm, Ready, BNT, Building, Next, Technology, Access, Funds, Free, South, Florida"
        />
        <script type="text/javascript">
          {`
          (function(d, t) {
            var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
            v.onload = function() {
            window.voiceflow.chat.load({
            verify: { projectID: '65a72f4754f579e0d9b0eb99' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production',
              launch: {
                event: {
                  type: "launch",
                  payload: {
                    site: "SITE001",
                    pincode: "46100",
                    stage: "welcome"
                    }
                  }
                }
              })
            }
              v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
            })(document, 'script');
          `}
        </script>
      </Helmet>
      {/* End React Helmet for SEO */}

      {/*<AnimatedCursor*/}
      {/*  innerSize={8}*/}
      {/*  outerSize={44}*/}
      {/*  color="220, 53, 69"*/}
      {/*  outerAlpha={0.3}*/}
      {/*  innerScale={0.7}*/}
      {/*  outerScale={1.2}*/}
      {/*/>*/}
      {/*/!* End Animated Cursor *!/*/}

      <ScrollToTop/>
      {/* End Scroll To Top */}

      <AllRoutes/>
      {/* End All Routes */}
    </>
  );
};

export default App;
